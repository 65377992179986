<template>
  <div class="slider-widget">
    <hooper-container
      :style="{ '--gap': `${gap / 2}px` }"
      :settings="sliderSettings"
      infiniteScroll
    >
      <hooper-slide
        v-for="(item, i) in list"
        :key="i"
        class="d-flex flex-column align-center justify-start"
      >
        <slot name="item" v-bind="item"></slot>
      </hooper-slide>
      <hooper-navigation slot="hooper-addons"></hooper-navigation>
    </hooper-container>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    itemsToShow: {
      type: Object,
    },
    gap: {
      type: Number,
      default: 16,
    },
  },
  computed: {
    sliderSettings() {
      return {
        autoPlay: true,
        playSpeed: 6000,
        wheelControl: false,
        itemsToShow: this.itemsToShow.xs,
        breakpoints: {
          600: {
            itemsToShow: this.itemsToShow.sm,
          },
          960: {
            itemsToShow: this.itemsToShow.md,
          },
          1264: {
            itemsToShow: this.itemsToShow.lg,
          },
          1900: {
            itemsToShow: this.itemsToShow.xl,
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-widget::v-deep {
  margin: 0px calc(0px - var(--gap));

  .hooper {
    height: auto !important;
    &-slide {
      padding: 0px var(--gap);
    }
  }

  ul {
    padding-left: 0 !important;
  }

  .hooper-prev {
    left: -60px;

    @media screen and (max-width: 960px) {
      left: -12px;
    }
  }

  .hooper-next {
    right: -60px;

    @media screen and (max-width: 960px) {
      right: -12px;
    }
  }

  .hooper-prev svg,
  .hooper-next svg {
    width: 38px !important;
    height: 38px !important;
  }
}
</style>
