<template>
  <v-sheet class="informations-section" color="primary">
    <div
      class="informations-section-map"
      v-html="informations.local_embed"
    ></div>
    <div class="informations-section-buttons">
      <div
        v-for="{ icon, name, text } in buttons"
        :key="name"
        class="white--text text-center pt-11"
      >
        <v-btn color="secondary" height="80px" width="80px" fab depressed>
          <v-icon size="30px">{{ icon }}</v-icon>
        </v-btn>
        <div
          class="informations-section-button-name font-weight-bold mt-4 mb-2"
        >
          {{ name }}
        </div>
        <div class="text-caption text-sm-body-2 px-2" v-html="text"></div>
      </div>
    </div>
    <aside class="empty"></aside>
  </v-sheet>
</template>

<script>
import { getContact } from "../../utils/getContact";

export default {
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    buttons() {
      const contact = getContact(this.informations);

      return [
        contact.local,
        contact.whatsapp,
        contact.email,
        contact.opening_hours,
      ];
    },
  },
};
</script>

<style lang="scss">
.informations-section {
  display: grid;
  grid-template-columns: auto 248px auto;
  align-items: center;
  column-gap: 18px;
  height: 584px;

  .informations-section-map {
    height: 100%;

    iframe {
      width: 100%;
      height: 100%;
      display: flex;
    }
  }

  .informations-section-buttons {
    display: grid;
    grid-template-columns: 229px 229px;
    grid-auto-rows: 229px;
    height: max-content;
    width: 100%;

    > :first-child {
      border-right: 1px solid rgba(255, 255, 255, 0.1);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    > :last-child {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      border-left: 1px solid rgba(255, 255, 255, 0.1);
    }

    .informations-section-button-name {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 960px) {
  .informations-section {
    grid-template-columns: 1fr;
    height: auto;

    iframe {
      aspect-ratio: 2;
    }

    .informations-section-buttons {
      display: grid;
      grid-template-columns: 229px 229px;
      grid-auto-rows: 229px;
      justify-content: center;
      padding: 20px 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .informations-section {
    iframe {
      aspect-ratio: 1;
    }

    .informations-section-buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: 229px;
    }
  }
}
</style>
