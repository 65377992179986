<template>
  <div>
    <v-sheet
      class="
        white--text
        text-h6 text-sm-h5 text-md-h4 text-center
        font-weight-bold
        py-3 py-md-6 py-lg-8
      "
      color="primary"
    >
      {{ item.title }}
    </v-sheet>

    <v-container class="pt-4 pt-md-6 pt-lg-8 pb-10 pb-md-13 pb-lg-16">
      <v-row justify="center">
        <v-col cols="12" md="9">
          <img
            v-if="item.img"
            class="d-block"
            :src="files + item.img + '?resize=1&w=' + (xsOnly ? 600 : 1200)"
            :alt="item.title"
            width="100%"
          />

          <div
            v-if="item.text"
            class="text-subtitle-1 ck-content item-text"
            :class="{ 'mt-4 mt-md-6': item.img }"
            v-html="item.text"
          ></div>

          <div class="mt-4 mt-md-6">
            <ButtonWidget
              color="#25d366"
              :href="getWhatsappLinkFromPhone(informations.whatsapp)"
              target="_blank"
              align="center"
            >
              <v-icon class="mr-2" color="white" size="22">fa-whatsapp</v-icon>

              <span class="white--text font-roboto"> Mais informações </span>
            </ButtonWidget>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ButtonWidget from "../components/widgets/ButtonWidget.vue";
import { getWhatsappLinkFromPhone } from "../utils/getWhatsappLinkFromPhone";

const _config = {
  mod: "workplaceSafety",
};

export default {
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    itemId() {
      return this.$route.params.item.replace(/^.*-([0-9]+)$/, "$1");
    },
    item() {
      return this.$rest(_config.mod).item;
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    getItem() {
      this.$rest(_config.mod)
        .get({ id: this.itemId })
        .catch(() => this.$router.replace("/error404"));
    },
    getWhatsappLinkFromPhone,
  },
  watch: {
    itemId() {
      this.getItem();
    },
  },
  mounted() {
    this.getItem();
  },
  components: {
    ButtonWidget,
  },
};
</script>
