<template>
  <div>
    <v-sheet
      class="
        white--text
        text-h6 text-sm-h5 text-md-h4 text-center
        font-weight-bold
        py-3 py-md-6 py-lg-8
      "
      color="primary"
    >
      {{ item.title || title }}
    </v-sheet>

    <v-container class="pt-4 pt-md-6 pt-lg-8 pb-10 pb-md-13 pb-lg-16">
      <v-row justify="center">
        <v-col v-if="item.img" cols="12" md="4">
          <img
            class="d-block"
            :src="files + item.img + '?resize=1&w=' + (xsOnly ? 600 : 1200)"
            :alt="item.title"
            width="100%"
          />
        </v-col>

        <v-col cols="12" md="8">
          <div
            v-if="item.text"
            class="text-subtitle-1 ck-content"
            v-html="item.text"
          ></div>
        </v-col>
      </v-row>
    </v-container>

    <Exams />
  </div>
</template>

<script>
import Exams from "./Exams.vue";

const _config = {
  mod: "occupationalMedicine",
};

const _data = {
  title: "Medicina do Trabalho",
};

export default {
  data: () => ({
    ..._data,
  }),
  created() {
    this.$rest(_config.mod).get({
      save: (state, data) => (state.item = data),
    });
  },
  computed: {
    item() {
      return this.$rest(_config.mod).item;
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  components: {
    Exams,
  },
};
</script>
