var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative slides slop"},[(_vm.loading)?_c('LoadingWidget'):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(!_vm.loading)?_c('div',[_c('hooper-container',{ref:"carousel",staticClass:"slides-container",staticStyle:{"height":"auto"},attrs:{"wheelControl":false,"mouseDrag":_vm.items.length > 1,"touchDrag":_vm.items.length > 1,"infiniteScroll":_vm.items.length > 1},on:{"slide":_vm.updateCarousel}},_vm._l((_vm.items),function(ref,keySlide){
var desktop = ref.desktop;
var mobile = ref.mobile;
var link = ref.link;
var target = ref.target;
return _c('hooper-slide',{key:keySlide,staticClass:"item"},[(link)?_c('a',{attrs:{"href":link,"target":target ? '_blank' : '_self'}},[_c('img',{staticClass:"d-block item-img",attrs:{"src":_vm.files +
                (_vm.xs && mobile
                  ? (mobile + "?resize=1&w=600")
                  : (desktop + "?resize=1&w=1920")),"width":"100%"}})]):_c('img',{staticClass:"d-block item-img",attrs:{"src":_vm.files +
              (_vm.xs && mobile
                ? (mobile + "?resize=1&w=600")
                : (desktop + "?resize=1&w=1920")),"width":"100%"}})])}),1),(_vm.items.length > 1)?_c('div',{staticClass:"d-flex justify-space-between px-3 px-md-4 px-lg-5 slides-nav"},[_c('a',{staticClass:"\n            d-flex\n            align-center\n            justify-center\n            elevation-2\n            pr-1\n            slides-nav-item\n          ",on:{"click":function($event){$event.preventDefault();return _vm.slidePrev.apply(null, arguments)}}},[_c('v-icon',{attrs:{"color":"primary","size":_vm.xs ? 20 : 30}},[_vm._v(" fa-angle-left ")])],1),_c('a',{staticClass:"\n            d-flex\n            align-center\n            justify-center\n            elevation-2\n            pl-1\n            slides-nav-item\n          ",on:{"click":function($event){$event.preventDefault();return _vm.slideNext.apply(null, arguments)}}},[_c('v-icon',{attrs:{"color":"primary","size":_vm.xs ? 20 : 30}},[_vm._v(" fa-angle-right ")])],1)]):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }