<template>
  <v-container v-if="items.length" class="py-10 py-md-13 py-lg-16">
    <TitleWidget>
      {{ title }}
    </TitleWidget>

    <SliderWidget class="mt-6 mt-md-8" :itemsToShow="itemsToShow" :list="items">
      <template #item="item">
        <PartnerCard v-bind="item" />
      </template>
    </SliderWidget>
  </v-container>
</template>

<script>
import TitleWidget from "../widgets/TitleWidget.vue";
import SliderWidget from "../widgets/SliderWidget.vue";
import PartnerCard from "../cards/PartnerCard.vue";

const _config = {
  mod: "partners",
};

const _sliderSettings = {
  itemsToShow: {
    xs: 2,
    sm: 3,
    md: 4,
    lg: 5,
    xl: 5,
  },
};

const _data = {
  title: "Parceiros",
  ..._sliderSettings,
};

export default {
  data: () => ({
    ..._data,
  }),
  computed: {
    items() {
      return this.$rest(_config.mod).list;
    },
  },
  mounted() {
    this.$rest(_config.mod).get();
  },
  components: {
    TitleWidget,
    SliderWidget,
    PartnerCard,
  },
};
</script>
