<template>
  <TooltipWidget bottom :tip="title">
    <v-card class="item" v-bind="{ href: link, target: '_blank' }" flat tile>
      <img class="d-block item-img" :src="files + img" width="100%" />
    </v-card>
  </TooltipWidget>
</template>

<script>
import TooltipWidget from "../widgets/TooltipWidget.vue";

export default {
  props: {
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
  },
  components: {
    TooltipWidget,
  },
};
</script>

<style lang="scss" scoped>
.item {
  &-img {
    filter: grayscale(1);
    height: 100px;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
    transition: all 0.1s linear;
  }

  &:hover {
    .item-img {
      filter: grayscale(0);
    }
  }
}
</style>
