import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import CoursesListView from "../views/CoursesListView.vue";
import CoursesItemView from "../views/CoursesItemView.vue";
import WorkplaceSafetyListView from "../views/WorkplaceSafetyListView.vue";
import WorkplaceSafetyItemView from "../views/WorkplaceSafetyItemView.vue";
import OccupationalMedicineView from "../views/OccupationalMedicine/Index.vue";
import ExamsItemView from "../views/ExamsItemView.vue";
import Error404View from "../views/Error404View.vue";
import ContactResponseView from "../views/ContactResponseView.vue";
import PolicyPrivacyView from "../views/PolicyPrivacyView.vue";
// import UnderConstructionView from "../views/UnderConstructionView.vue";

Vue.use(VueRouter);

const routes = [
  {
    name: "",
    path: "/",
    component: HomeView,
  },
  {
    name: "Treinamentos e Capacitações",
    path: "/treinamentos-e-capacitacoes",
    component: CoursesListView,
  },
  {
    name: "Treinamentos e Capacitações",
    path: "/treinamentos-e-capacitacoes/:item",
    component: CoursesItemView,
  },
  {
    name: "Segurança do Trabalho",
    path: "/seguranca-do-trabalho",
    component: WorkplaceSafetyListView,
  },
  {
    name: "Segurança do Trabalho",
    path: "/seguranca-do-trabalho/:item",
    component: WorkplaceSafetyItemView,
  },
  {
    name: "Medicina do Trabalho",
    path: "/medicina-do-trabalho",
    component: OccupationalMedicineView,
  },
  {
    name: "Exame",
    path: "/exames/:item",
    component: ExamsItemView,
  },
  {
    name: "Obrigado!",
    path: "/obrigado",
    component: ContactResponseView,
  },
  {
    name: "Política de Privacidade",
    path: "/politica-de-privacidade",
    component: PolicyPrivacyView,
  },
  {
    path: "/erro404",
    name: "Erro 404",
    component: Error404View,
  },
  {
    path: "*",
    name: "Erro 404",
    component: Error404View,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

export default router;
