<template>
  <v-container v-if="items.length" class="pb-10 pb-md-13 pb-lg-16">
    <TitleWidget>
      {{ title }}
    </TitleWidget>

    <div class="mt-4 mt-md-6 grid-list">
      <ServiceCardCover
        v-for="(item, keyItems) in items"
        :key="keyItems"
        v-bind="item"
      />
    </div>
  </v-container>
</template>

<script>
import TitleWidget from "../../components/widgets/TitleWidget.vue";
import ServiceCardCover from "../../components/cards/ServiceCardCover.vue";
import { formatToURL } from "../../utils/formatToURL";

const _config = {
  mod: "exams",
  path: "/exames",
};

const _data = {
  title: "Exames",
};

export default {
  data: () => ({
    ..._data,
  }),
  computed: {
    items() {
      return this.$rest(_config.mod).list.map(({ id, img, title }) => ({
        img,
        title,
        to: `${_config.path}/${formatToURL(title)}-${id}`,
      }));
    },
  },
  methods: {
    getList() {
      this.$rest(_config.mod).get();
    },
  },
  watch: {
    $route() {
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
  components: {
    TitleWidget,
    ServiceCardCover,
  },
};
</script>

<style lang="scss" scoped>
.grid-list {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: 959.9px) {
    gap: 12px;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
