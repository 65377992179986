<template>
  <div>
    <v-sheet
      class="
        white--text
        text-h6 text-sm-h5 text-md-h4 text-center
        font-weight-bold
        py-3 py-md-6 py-lg-8
      "
      color="primary"
    >
      {{ title }}
    </v-sheet>

    <LoadingWidget v-if="loading" />

    <v-container v-else class="pt-4 pt-md-6 pt-lg-8 pb-10 pb-md-13 pb-lg-16">
      <template v-if="items.length">
        <div class="grid-list">
          <ServiceCardCover
            v-for="(item, keyItems) in items"
            :key="keyItems"
            v-bind="item"
          />
        </div>

        <v-pagination
          v-if="pagesLength > 1"
          class="mt-10"
          color="secondary"
          v-model="page"
          :length="pagesLength"
        ></v-pagination>
      </template>

      <v-alert v-else class="mt-6" type="info">
        Nenhum conteúdo adicionado até o momento.
      </v-alert>
    </v-container>
  </div>
</template>

<script>
import LoadingWidget from "../components/widgets/LoadingWidget.vue";
import ServiceCardCover from "../components/cards/ServiceCardCover.vue";
import { formatToURL } from "../utils/formatToURL";

const _config = {
  mod: "courses",
  path: "/treinamentos-e-capacitacoes",
};

const _pagination = {
  itemsPerPage: 24,
  returnTotalItems: 1,
};

const _data = {
  title: "Treinamentos e Capacitações",
  loading: true,
  totalItems: 0,
};

export default {
  data: () => ({
    ..._data,
  }),
  computed: {
    items() {
      return this.$rest(_config.mod).list.map(({ id, img, title }) => ({
        img,
        title,
        to: `${_config.path}/${formatToURL(title)}-${id}`,
      }));
    },
    page: {
      get() {
        const p = this.$route.query.p;
        return p ? parseInt(p) : 1;
      },
      set(v) {
        const query = { ...this.$route.query };
        query.p = v;

        return this.$router.push({ query });
      },
    },
    pagesLength() {
      return Math.ceil(this.totalItems / _pagination.itemsPerPage);
    },
  },
  methods: {
    getList() {
      this.loading = true;

      const params = {
        ..._pagination,
        page: this.page,
      };

      this.$rest(_config.mod)
        .get({
          params,
          save: (state, data) => {
            let items = [];
            if (_pagination.returnTotalItems) {
              items = data.list;
              this.totalItems = data.totalItems;
            } else {
              items = data;
            }

            state.list = items;
          },
        })
        .finally(() => (this.loading = false));
    },
  },
  watch: {
    $route() {
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
  components: {
    LoadingWidget,
    ServiceCardCover,
  },
};
</script>

<style lang="scss" scoped>
.grid-list {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: 959.9px) {
    gap: 12px;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
