<template>
  <v-sheet
    width="100%"
    height="100%"
    color="#efefef"
    class="d-flex align-center justify-center py-10 py-md-13 py-lg-16"
  >
    <v-container>
      <div class="d-flex flex-column align-center">
        <v-icon :size="xs ? 100 : 140" :color="iconColor">
          {{ iconText }}
        </v-icon>

        <div class="text-h6 text-sm-h5 font-weight-bold text-center mt-7">
          {{ intro }}
        </div>

        <div
          v-if="text"
          class="
            grey--text
            text--darken-2 text-body-1 text-sm-h6 text-center
            mt-3
          "
        >
          {{ text }}
        </div>

        <ButtonWidget @click="back" :height="sm ? `40px` : `55px`" class="mt-8">
          <v-icon size="16" left> fas fa-undo </v-icon>
          Voltar
        </ButtonWidget>
      </div>
    </v-container>
  </v-sheet>
</template>

<script>
import ButtonWidget from "../widgets/ButtonWidget.vue";

export default {
  props: {
    intro: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    iconText: {
      type: String,
      default: "far fa-check-circle",
    },
    iconColor: {
      type: String,
      default: "success",
    },
  },
  computed: {
    sm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    xs() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    meta() {
      return this.$route.meta;
    },
  },
  methods: {
    back() {
      window.history.back();
    },
  },
  components: {
    ButtonWidget,
  },
};
</script>
