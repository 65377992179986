<template>
  <v-card :to="to" class="flex-column align-center d-flex item" flat>
    <img
      class="d-block item-img"
      :src="files + icon + '?resize=1&w=116'"
      :alt="title"
    />

    <div class="mt-2 text-center item-title">{{ title }}</div>
  </v-card>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  &-img {
    max-width: 116px;
    aspect-ratio: 1;
    filter: invert(56%) sepia(2%) saturate(619%) hue-rotate(214deg)
      brightness(95%) contrast(80%);
  }

  &-title {
    font-size: 18px !important;
    font-weight: 600;
    color: #87868a;
  }

  &:hover {
    .item-img {
      filter: invert(26%) sepia(85%) saturate(5293%) hue-rotate(191deg)
        brightness(94%) contrast(92%);
    }

    .item-title {
      color: #0a71b9;
    }
  }
}
</style>
