<template>
  <SliderWidget :itemsToShow="itemsToShow" :list="list">
    <template #item="item">
      <component :is="component" v-bind="item" />
    </template>
  </SliderWidget>
</template>

<script>
import ServiceCardCover from "../cards/ServiceCardCover.vue";
import ServiceCardIcon from "../cards/ServiceCardIcon.vue";
import SliderWidget from "./SliderWidget.vue";

export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: "icon",
    },
    itemsToShow: {
      type: Object,
    },
  },
  computed: {
    component() {
      const components = {
        cover: ServiceCardCover,
        icon: ServiceCardIcon,
      };

      return components[this.type];
    },
  },
  components: {
    SliderWidget,
  },
};
</script>
