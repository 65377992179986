<template>
  <div
    :class="[
      'd-flex title-widget text-h5 text-md-h4 font-weight-bold pb-2',
      className,
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    align: {
      type: String,
      default: "center",
      validator: (val) => ["left", "center", "right"].includes(val),
    },
  },
  computed: {
    className() {
      const classNameAlign = {
        left: "text-left justify-start",
        center: "text-center justify-center",
        right: "text-right justify-end",
      };

      return classNameAlign[this.align] || "";
    },
  },
};
</script>

<style>
.title-widget {
  display: inline-block;
  color: #1e1e1e;
}
</style>
