var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"header-shell-desktop",attrs:{"app":"","scroll-threshold":"100","height":"130px","color":"white","hide-on-scroll":"","elevate-on-scroll":""}},[_c('v-container',{staticClass:"py-5 d-flex justify-space-between"},[_c('router-link',{attrs:{"to":"/"}},[(_vm.informations.img)?_c('img',{staticClass:"d-block",attrs:{"src":_vm.files + _vm.informations.img,"alt":_vm.informations.name,"height":"80px"}}):_vm._e()]),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex px-2 justify-end"},_vm._l((_vm.topButtons),function(ref,i){
var name = ref.name;
var link = ref.link;
var icon = ref.icon;
var size = ref.size;
return _c('TooltipWidget',{key:i,attrs:{"tip":name,"bottom":""}},[_c('v-btn',{staticClass:"ml-4",attrs:{"href":link,"target":"_blank","height":"32px","width":"32px","icon":""}},[_c('v-icon',{attrs:{"color":"black","size":size}},[_vm._v(_vm._s(icon))])],1)],1)}),1),_c('v-divider',{staticClass:"mb-3 mt-1 ml-auto"}),_c('div',{staticClass:"d-flex align-center"},[_vm._l((_vm.menu),function(ref,i){
var text = ref.text;
var to = ref.to;
var dropdown = ref.dropdown;
return [(!dropdown)?_c('v-btn',{key:("A" + i),staticClass:"ml-3 text-none text-body-1",attrs:{"to":to,"text":""}},[_vm._v(" "+_vm._s(text)+" ")]):_c('v-menu',{key:("B" + i),attrs:{"offset-y":"","tile":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3 text-none text-body-1",attrs:{"depressed":"","text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(text)+" "),_c('v-icon',{staticClass:"ml-3",attrs:{"size":"12"}},[_vm._v(" fa-caret-down ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"tile":""}},_vm._l((dropdown),function(item,i){return _c('v-list-item',{key:i,attrs:{"to":item.to}},[_c('v-list-item-title',{staticClass:"black--text text--darken-3"},[_vm._v(" "+_vm._s(item.text)+" ")])],1)}),1)],1)]}),_c('ButtonWidget',{staticClass:"ml-4 font-roboto",attrs:{"height":"42px"}},[_vm._v(" Área do Cliente ")])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }