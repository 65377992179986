<template>
  <v-card
    :to="to"
    class="d-flex flex-column align-center item"
    height="100%"
    flat
  >
    <img
      @error="onError"
      class="d-block item-img"
      :class="{ 'rounded-lg': smAndDown, 'rounded-xl': !smAndDown }"
      :src="files + img + '?resize=1&w=362'"
      :alt="title"
    />

    <div class="my-5">
      <div class="text-center primary--text item-title">
        {{ title }}
      </div>

      <div v-if="intro" class="mt-4 text-center item-intro">{{ intro }}</div>
    </div>
  </v-card>
</template>

<script>
import DefaultImg from "../../assets/img/default-img.png";

export default {
  props: {
    img: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      required: true,
    },
    intro: {
      type: String,
      default: "",
    },
    to: {
      type: String,
      required: true,
    },
  },
  computed: {
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    onError(e) {
      e.target.src = DefaultImg;
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  &-img {
    aspect-ratio: 362 / 220;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
  }

  &-title {
    font-size: 21px !important;
    font-weight: 600;

    @media screen and (max-width: 959.9px) {
      font-size: 18px !important;
    }

    @media screen and (max-width: 599.9px) {
      font-size: 16px !important;
    }
  }
}
</style>
