<template>
  <v-app-bar
    app
    scroll-threshold="100"
    height="130px"
    color="white"
    class="header-shell-desktop"
    hide-on-scroll
    elevate-on-scroll
  >
    <v-container class="py-5 d-flex justify-space-between">
      <router-link to="/">
        <img
          v-if="informations.img"
          :src="files + informations.img"
          :alt="informations.name"
          class="d-block"
          height="80px"
        />
      </router-link>

      <div class="d-flex flex-column">
        <div class="d-flex px-2 justify-end">
          <TooltipWidget
            v-for="({ name, link, icon, size }, i) in topButtons"
            :key="i"
            :tip="name"
            bottom
          >
            <v-btn
              :href="link"
              target="_blank"
              height="32px"
              width="32px"
              class="ml-4"
              icon
            >
              <v-icon color="black" :size="size">{{ icon }}</v-icon>
            </v-btn>
          </TooltipWidget>
        </div>

        <v-divider class="mb-3 mt-1 ml-auto"></v-divider>

        <div class="d-flex align-center">
          <template v-for="({ text, to, dropdown }, i) in menu">
            <v-btn
              v-if="!dropdown"
              :to="to"
              :key="`A${i}`"
              class="ml-3 text-none text-body-1"
              text
            >
              {{ text }}
            </v-btn>

            <v-menu v-else :key="`B${i}`" offset-y tile>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="ml-3 text-none text-body-1"
                  depressed
                  text
                >
                  {{ text }}
                  <v-icon class="ml-3" size="12"> fa-caret-down </v-icon>
                </v-btn>
              </template>
              <v-list tile>
                <v-list-item
                  v-for="(item, i) in dropdown"
                  :key="i"
                  :to="item.to"
                >
                  <v-list-item-title class="black--text text--darken-3">
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <ButtonWidget class="ml-4 font-roboto" height="42px">
            Área do Cliente
          </ButtonWidget>
        </div>
      </div>
    </v-container>
  </v-app-bar>
</template>

<script>
import { getContact } from "../../../utils/getContact";
import { getSocial } from "../../../utils/getSocial";
import ButtonWidget from "../../widgets/ButtonWidget.vue";
import TooltipWidget from "../../widgets/TooltipWidget.vue";

export default {
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    topButtons() {
      const social = getSocial(this.informations);
      const contact = getContact(this.informations);

      const buttons = [
        social.instagram,
        social.facebook,
        contact.whatsapp,
        contact.local,
      ];

      return buttons.filter((button) => !!button);
    },
  },
  components: {
    ButtonWidget,
    TooltipWidget,
  },
};
</script>

<style>
.header-shell-desktop .v-divider {
  width: 300px;
}
</style>
