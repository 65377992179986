import axios from "axios";
import { createModule } from "./module";
import { camelCaseToHyphen, validateOptions } from "./utils";
import moduleGetters from "./module/getters";
import moduleActions from "./module/actions";
import store from "../vuex";

const http = axios.create();

const getters = Object.keys(moduleGetters);
const actions = Object.keys(moduleActions);

function loadState(store) {
  const stateElement = document.getElementById("state");
  if (!stateElement) {
    return;
  }

  const stateData = JSON.parse(stateElement.textContent);

  for (const module in stateData) {
    store.registerModule(
      "_rest_" + module,
      createModule(camelCaseToHyphen(module))
    );

    const moduleData = stateData[module];

    if (Array.isArray(moduleData)) {
      store.state["_rest_" + module].list = moduleData;
    } else {
      store.state["_rest_" + module].item = moduleData;
    }

    store.state["_rest_" + module].loaded = true;
  }
}

const VuexRest = {
  install: (Vue, { base }) => {
    http.defaults.baseURL = base || "/";

    loadState(store);

    Vue.prototype.$rest = function (module) {
      if (typeof module !== "string")
        throw new TypeError("O parâmetro <module> deve ser uma string.");

      const callAction = (action, data) => {
        validateOptions(data || {});
        return store.dispatch(`${"_rest_" + module}/${action}`, data || {});
      };

      if (!store.hasModule("_rest_" + module)) {
        store.registerModule(
          "_rest_" + module,
          createModule(camelCaseToHyphen(module))
        );
      }

      const { item, list } = store.state["_rest_" + module];

      const rest = {
        item,
        list,
        getters: {},
      };

      for (let getter of getters)
        rest.getters[getter] = store.getters[`${"_rest_" + module}/${getter}`];

      for (let action of actions)
        rest[action] = (data) => callAction(action, data);

      return rest;
    };
  },
};

export { http };
export default VuexRest;
