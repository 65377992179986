<template>
  <SectionTemplate title="Contato" class="contact-section" :container="false">
    <div class="text-center mb-3">
      Preencha o formulário abaixo e entre em contato conosco.
    </div>
    <div class="d-flex justify-center px-4">
      <ContactForm :action="files + formAction" response-to="/obrigado" />
    </div>
  </SectionTemplate>
</template>

<script>
import SectionTemplate from "../../components/templates/SectionTemplate.vue";
import ContactForm from "../../components/forms/ContactForm.vue";

export default {
  data: () => ({
    formAction: "/rest/email/contact-us",
  }),
  components: {
    SectionTemplate,
    ContactForm,
  },
};
</script>

<style>
.contact-section {
  background-color: #e6eff4;
}
</style>
