export const mixin = {
  data: () => ({
    fieldProps: {
      solo: true,
      flat: true,
      color: "primary",
      // style: "background-color: #;",
    },
  }),
};
