<template>
  <footer class="shell-footer">
    <v-sheet height="1px" color="primary"></v-sheet>

    <v-sheet color="white" height="106px">
      <v-container class="py-0 h-100 d-flex justify-space-between align-center">
        <router-link to="/">
          <img
            v-if="informations.img"
            :src="files + informations.img"
            :alt="informations.name"
            height="74px"
            class="d-block mx-auto mx-md-0"
          />
        </router-link>

        <div class="d-flex">
          <TooltipWidget
            v-for="({ name, link, icon, size }, i) in social"
            :key="i"
            :tip="name"
            bottom
          >
            <v-btn
              class="ml-3"
              :href="link"
              target="_blank"
              height="45px"
              width="45px"
              color="primary"
              fab
              depressed
            >
              <v-icon color="white" :size="size">{{ icon }}</v-icon>
            </v-btn>
          </TooltipWidget>
        </div>
      </v-container>
    </v-sheet>

    <v-sheet color="black">
      <v-container
        class="
          py-3
          h-100
          d-flex
          flex-column flex-sm-row
          align-center
          justify-space-between
        "
      >
        <div class="white--text text-caption text-center mb-4 mb-sm-0">
          Copyright ©
          <b class="font-weight-bold">2023 {{ informations.name }}</b
          >.
          <span class="d-block d-sm-inline-block">
            Todos os direitos reservados.
          </span>
        </div>

        <a class="d-flex" href="https://www.mrxweb.com.br/" target="_blank">
          <img
            class="shell-footer-logo"
            src="../../assets/img/mrxweb.svg"
            alt="MRX Web Sites, Publicidade, Marketing Digital, Produção de Vídeos - Sinop/MT"
            title="MRX Web Sites, Publicidade, Marketing Digital, Produção de Vídeos - Sinop/MT"
            height="20px"
          />
        </a>
      </v-container>
    </v-sheet>
  </footer>
</template>

<script>
import { getSocial } from "../../utils/getSocial";
import TooltipWidget from "../widgets/TooltipWidget.vue";

export default {
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    social() {
      return getSocial(this.informations);
    },
  },
  components: { TooltipWidget },
};
</script>

<style lang="scss" scoped>
.shell-footer {
  .grid-list {
    display: grid;
    gap: 24px 48px;
    grid-template-columns: auto 1fr auto;
    justify-content: space-between;

    @media screen and (min-width: 600.001px) and (max-width: 960px) {
      gap: 24px;
      grid-template-columns: auto auto;

      &-item:first-child {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    .btn-contact::v-deep {
      .v-btn__content {
        max-width: 100%;
        white-space: normal;
      }
    }
  }
}

.iframe-map iframe {
  width: 100% !important;
  height: 500px !important;
  display: block;
}
</style>
