<template>
  <SectionTemplate title="Segurança do Trabalho" class="workplace-safety">
    <ServicesSliderWidget :list="list" :items-to-show="itemsToShow" />
  </SectionTemplate>
</template>

<script>
import SectionTemplate from "../../components/templates/SectionTemplate.vue";
import ServicesSliderWidget from "../widgets/ServicesSliderWidget.vue";
import { formatToURL } from "../../utils/formatToURL";

const _config = {
  mod: "workplace-safety",
  path: "/seguranca-do-trabalho",
};

const _data = {
  itemsToShow: {
    xl: 5,
    lg: 5,
    md: 4,
    sm: 3,
    xs: 2,
  },
};

export default {
  data: () => ({
    ..._data,
  }),
  computed: {
    list() {
      return this.$rest(_config.mod).list.map(
        ({ id, img, icon, title, intro }) => ({
          img,
          icon,
          title,
          intro,
          to: `${_config.path}/${formatToURL(title)}-${id}`,
        })
      );
    },
  },
  beforeCreate() {
    this.$rest(_config.mod)
      .get()
      .then(() => (this.loading = false));
  },
  components: {
    SectionTemplate,
    ServicesSliderWidget,
  },
};
</script>
