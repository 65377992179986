<template>
  <v-container class="about-section">
    <img
      :src="files + about.img + '?resize=1&w=585'"
      :alt="informations.name"
    />
    <div>
      <div class="about-section-title font-weight-bold mb-2">
        {{ about.title }}
      </div>
      <div v-html="about.text"></div>
    </div>
  </v-container>
</template>

<script>
export default {
  computed: {
    about() {
      return this.$rest("about").item;
    },
    informations() {
      return this.$rest("informations").item;
    },
  },
  beforeCreate() {
    this.$rest("about")
      .get({
        save: (state, data) => (state.item = data),
      })
      .then(() => (this.loading = false));
  },
};
</script>

<style lang="scss">
.about-section {
  display: grid;
  grid-template-columns: 585px auto;
  align-items: center;
  column-gap: 68px;
  padding: 90px 12px 80px 12px;
  img {
    width: 100%;
  }

  .about-section-title {
    font-size: 36px;
  }
}

@media screen and (max-width: 1264px) {
  .about-section {
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
  }
}

@media screen and (max-width: 960px) {
  .about-section {
    grid-template-columns: 1fr;
    row-gap: 24px;
  }
}
</style>
