<template>
  <SectionTemplate :title="title">
    <v-container>
      <ServicesSliderWidget
        :list="featuredList"
        :items-to-show="featuredItemsToShow"
        type="cover"
        class="mb-15"
      />
      <ServicesSliderWidget
        :list="unfeaturedList"
        :items-to-show="unfeaturedItemsToShow"
      />
    </v-container>
  </SectionTemplate>
</template>

<script>
import SectionTemplate from "../templates/SectionTemplate.vue";
import ServicesSliderWidget from "../widgets/ServicesSliderWidget.vue";
import { formatToURL } from "../../utils/formatToURL";

const _config = {
  mod: "courses",
  path: "/treinamentos-e-capacitacoes",
};

const _data = {
  title: "Treinamentos e Capacitações",
  unfeaturedItemsToShow: {
    xl: 5,
    lg: 5,
    md: 4,
    sm: 3,
    xs: 2,
  },
  featuredItemsToShow: {
    xl: 3,
    lg: 3,
    md: 2,
    sm: 1,
    xs: 1,
  },
};

export default {
  data: () => ({
    ..._data,
  }),
  computed: {
    unfeaturedList() {
      return this.$rest(_config.mod)
        .list.filter(({ featured }) => !featured)
        .map(({ id, img, icon, title, intro }) => ({
          img,
          icon,
          title,
          intro,
          to: `${_config.path}/${formatToURL(title)}-${id}`,
        }));
    },
    featuredList() {
      return this.$rest(_config.mod)
        .list.filter(({ featured }) => featured)
        .map(({ id, img, icon, title, intro }) => ({
          img,
          icon,
          title,
          intro,
          to: `${_config.path}/${formatToURL(title)}-${id}`,
        }));
    },
  },
  beforeCreate() {
    this.$rest(_config.mod)
      .get()
      .then(() => (this.loading = false));
  },
  components: {
    SectionTemplate,
    ServicesSliderWidget,
  },
};
</script>
