<template>
  <div>
    <v-sheet
      class="
        white--text
        text-h5 text-md-h4 text-center
        font-weight-bold
        py-4 py-md-6 py-lg-8
      "
      color="primary"
    >
      {{ item.title || title }}
    </v-sheet>

    <LoadingWidget v-if="loading" />

    <v-container v-else class="pt-4 pt-md-6 pt-lg-8 pb-10 pb-md-13 pb-lg-16">
      <v-row justify="center">
        <v-col cols="12" md="9">
          <img
            v-if="item.img"
            class="d-block"
            :src="files + item.img + '?resize=1&w=' + (xsOnly ? 600 : 1200)"
            :alt="item.title"
            width="100%"
          />

          <div
            v-if="item.text"
            class="text-subtitle-1 ck-content item-text"
            :class="{ 'mt-4 mt-md-6': item.img }"
            v-html="item.text"
          ></div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import LoadingWidget from "../components/widgets/LoadingWidget.vue";

const _config = {
  mod: "exams",
};

const _data = {
  title: "Exames",
  loading: true,
};

export default {
  data: () => ({
    ..._data,
  }),
  computed: {
    itemId() {
      return this.$route.params.item.replace(/^.*-([0-9]+)$/, "$1");
    },
    item() {
      return this.$rest(_config.mod).item;
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    getItem() {
      this.loading = true;

      this.$rest(_config.mod)
        .get({ id: this.itemId })
        .catch(() => this.$router.replace("/error404"))
        .finally(() => (this.loading = false));
    },
  },
  watch: {
    itemId() {
      this.getItem();
    },
  },
  mounted() {
    this.getItem();
  },
  components: {
    LoadingWidget,
  },
};
</script>
