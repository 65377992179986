// const server =
//   process.env.NODE_ENV === "development"
//     ? process.env.VUE_APP_SERVER
//     : location.origin;

const server = process.env.VUE_APP_SERVER;

const menu = [
  {
    text: "Quem Somos",
    to: "#sobre",
  },
  {
    text: "Serviços",
    dropdown: [
      {
        text: "Treinamentos e Capacitações",
        to: "/treinamentos-e-capacitacoes",
      },
      {
        text: "Segurança do Trabalho",
        to: "/seguranca-do-trabalho",
      },
    ],
  },
  {
    text: "Medicina do Trabalho",
    to: "/medicina-do-trabalho",
  },
  {
    text: "Contato",
    to: "/#contato",
  },
];

export { server, menu };
