<template>
  <SectionTemplate>
    <div class="text-h4 text-md-h3 text-center">Política de Privacidade</div>
    <PolicyPrivacyWidget class="mt-10" />
  </SectionTemplate>
</template>

<script>
import SectionTemplate from "../components/templates/SectionTemplate.vue";
import PolicyPrivacyWidget from "../components/widgets/PolicyPrivacyWidget.vue";

export default {
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
  },
  components: {
    SectionTemplate,
    PolicyPrivacyWidget,
  },
};
</script>

<style></style>
