<template>
  <div>
    <SlideShowSection />
    <AboutSection id="sobre" />
    <CoursesSection id="cursos-e-treinamentos" />
    <WorkplaceSafetySection id="seguranca-do-trabalho" />
    <ContactSection id="contato" />
    <InformationsSection id="contato" />
    <PartnerSection />
  </div>
</template>

<script>
import SlideShowSection from "../components/sections/SlideShowSection.vue";
import AboutSection from "../components/sections/AboutSection.vue";
import CoursesSection from "../components/sections/CoursesSection.vue";
import WorkplaceSafetySection from "../components/sections/WorkplaceSafetySection.vue";
import ContactSection from "../components/sections/ContactSection.vue";
import InformationsSection from "../components/sections/InformationsSection.vue";
import PartnerSection from "../components/sections/PartnerSection.vue";

export default {
  components: {
    SlideShowSection,
    AboutSection,
    CoursesSection,
    WorkplaceSafetySection,
    ContactSection,
    InformationsSection,
    PartnerSection,
  },
};
</script>
