<template>
  <div
    v-if="informations.whatsapp_support || informations.whatsapp_commercial"
    class="whatsapp"
  >
    <div @mouseleave="active = false" class="d-flex">
      <v-sheet
        class="d-flex flex-column hover"
        :class="{ active: active }"
        color="white"
      >
        <v-btn
          v-if="informations.whatsapp_support"
          :href="informations.whatsapp_support"
          target="_blank"
          class="justify-start text-none"
          depressed
          block
        >
          <v-icon color="#25D366" left> fa-whatsapp </v-icon>
          Suporte
        </v-btn>

        <v-btn
          v-if="informations.whatsapp_commercial"
          :href="informations.whatsapp_commercial"
          target="_blank"
          class="justify-start text-none"
          depressed
          block
        >
          <v-icon color="#25D366" left> fa-whatsapp </v-icon>
          Comercial
        </v-btn>
      </v-sheet>

      <v-btn
        @mouseenter="active = true"
        class="mt-auto btn"
        target="_blank"
        color="#25D366"
        fab
      >
        <v-icon size="29" color="white">fab fa-whatsapp</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { getWhatsappLinkFromPhone } from "../../utils/getWhatsappLinkFromPhone";

export default {
  data: () => ({
    active: false,
  }),
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
  },
  methods: {
    getWhatsappLinkFromPhone,
  },
};
</script>

<style lang="scss" scoped>
.whatsapp {
  position: fixed;
  right: 16px;
  bottom: 16px;
  width: max-content;
  z-index: 9;

  .hover {
    border-radius: 6px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    display: none;
    margin-right: 15px;
    opacity: 0;
    transition: opacity 0.1s linear 0.1s;

    &.active {
      display: block;
      opacity: 1;
    }
  }
}
</style>
